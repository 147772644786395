import React from "react";

import "./App.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/Home";
import What from "./pages/What";
import Who from "./pages/Who";
import Contact from "./pages/Contact";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/what/" component={What} />
        <Route exact path="/who/" component={Who} />
        <Route exact path="/contact/" component={Contact} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
