import React from "react";
import img01 from "../images/who-01.png";
import img02 from "../images/who-02.png";
import img03 from "../images/who-03.png";

const who = () => {
  return (
    <main role="main" class="container">
      <h3 className="text-center">Who we are</h3>

      <div className="container">
        <div className="row">
          <div className="col g-0">
            <div className="container">
              <div className="row">
                <div className="col g-0">
                  <div className="card mb-3 border-0">
                    <div className="row g-0">
                      <div className="col-md-4">
                        {" "}
                        <img
                          src={img01}
                          width="100px"
                          height="100px"
                          alt="Dr. Stan Jeffery"
                          className="d-block m-auto"
                        />
                        <p className="text-center small">
                          <small>Dr Stan Jeffery Co-founder ARI</small>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title">Dr Stan Jeffery</h5>{" "}
                          <p className="card-text small">
                            Sydney Australia Co-founder Abundance Research
                            Institute, CEO NKG
                          </p>
                          <p className="card-text small">
                            BSC Electronics, MBA Monash University, Doctorate in
                            Technology Deakin University. Professional engineer,
                            Fellow Royal Society NSW, KSJ, OSJ. CEO and director
                            of three technology parks
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3 border-0">
                    <div className="row g-0">
                      <div className="col-md-4">
                        {" "}
                        <img
                          src={img02}
                          width="100px"
                          height="100px"
                          alt="Dr. Gary Sorensen"
                          className="d-block m-auto"
                        />
                        <p className="text-center small">
                          <small>Dr Gary Sorensen Co-founder ARI</small>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title">Dr Gary Sorensen </h5>{" "}
                          <p className="card-text small">
                            Tyrone , PA Co-founder Abundance Research Institute,
                            CEO RGT
                          </p>
                          <p className="card-text small">
                            BS in Civil & Environmental Engineering, MS in City
                            & Urban Planning, PhD in Environmental Design,
                            Environmental Engineer, First Army, Medical Service
                            Corps. University Prof and Researcher in Civil
                            Engineering & Architecture (Texas A&M University and
                            University of Tennessee)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            {" "}
            <p className="lead">
              New Kingdom Global (NKG) and Reborne Global Trust (RGT) have
              pooled resources to establish and grow the Abundance Research
              Institute globally for the benefit of God’s Kingdom on earth at
              this time.
            </p>
            <p>
              RGT is based in Pennsylvania USA and NKG is based in Sydney
              Australia.
            </p>
            <p>
              Based upon technology park establishment and incubator/start up
              experience over the past 35 years it is proposed to prepare a
              foundational H/O model in Sydney and then implement two initial
              establishment Abundance Research Institute Hub Centres with one in
              Australia with physical Centre of Excellence for Kingdom Business
              Growth with a virtual support and growth network of
              Kingdompreneurs in the India/China to NZ time zone. In parallel
              with one in Pennsylvania USA time zone the coverage would be North
              and South America.
            </p>
            <p>
              Then later on in UK/Europe would cover the region from Ireland to
              Iraq and Russia to Africa in the first instance with a hub and
              spoke Kingdom matrix structure.
            </p>
          </div>
          <div className="col-3">
            <img src={img03} width="250px" className="d-block m-auto" />
            <p className="text-center small">
              <small> Possible head office location in Sydney</small>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default who;
