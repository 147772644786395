import React from "react";
import axios from "axios";

import { useFormik } from "formik";

import img01 from "../images/contact-01.png";
import img02 from "../images/contact-02.png";

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.companyName) {
    errors.companyName = "Required";
  } else if (values.companyName.length > 20) {
    errors.companyName = "Must be 20 characters or less";
  }

  if (!values.address) {
    errors.address = "Required";
  } else if (values.address.length > 20) {
    errors.address = "Must be 20 characters or less";
  }
  if (!values.introductionBy) {
    errors.introductionBy = "Required";
  } else if (values.introductionBy.length > 20) {
    errors.introductionBy = "Must be 20 characters or less";
  }

  if (!values.message) {
    errors.message = "Required";
  } else if (values.message.length > 20) {
    errors.message = "Must be 20 characters or less";
  }

  return errors;
};

const Contact = () => {
  // handleSubmit = event => {
  //   alert(JSON.stringify(values, null, 2));
  //   event.preventDefault();
  //   this.setState({ isSubmitting: true });
  //   const { formValues, formValidity } = this.state;
  //   if (Object.values(formValidity).every(Boolean)) {
  //     alert("Form is validated! Submitting the form...");
  //     this.setState({ isSubmitting: false });
  //   } else {
  //     for (let key in formValues) {
  //       let target = {
  //         name: key,
  //         value: formValues[key]
  //       };
  //       this.handleValidation(target);
  //     }
  //     this.setState({ isSubmitting: false });
  //   },
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      address: "",
      introductionBy: "",
      message: "",
    },
    validate,
    onSubmit: (values, actions) => {
      //alert(JSON.stringify(values, null, 2));
      // e.preventDefault();
      axios({
        method: "POST",
        url: "https://www.abundanceresearchinstitute.com/email.php",
        data: values,
      }).then((response) => {
        //console.log(response.data);
        if (response.data.status === "success") {
          alert("Message Sent.");
          //this.resetForm();
          actions.resetForm({
            values: {
              // the type of `values` inferred to be Blog
              firstName: "",
              lastName: "",
              email: "",
              companyName: "",
              address: "",
              introductionBy: "",
              message: "",
            },
          });
        } else if (response.data.status === "fail") {
          alert("Message failed to send.");
          //console.log(response.data.error);
          response.data.error.map((sweetItem) => {
            console.log(sweetItem);
          });
        }
      });
    },
  });
  return (
    <main role="main" class="container">
      <h3 className="text-center">Contact</h3>

      <div className="container">
        <div className="row">
          <div className="col g-0"></div>
          <div className="col-6">
            {" "}
            <p className="lead"></p>
            <p>
              New Kingdom Global - Web site – http://www.NewKingdomGlobal.com
            </p>
            <p>Reborn Global Trust -Web site – http://Reborne.us</p>
            <p>
              For information and expressions of interest please enter the
              following information and return
            </p>
            <form onSubmit={formik.handleSubmit}>
              {/* <form onSubmit={this.handleSubmit}> */}
              <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                />
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div className="text-danger">{formik.errors.companyName}</div>
                ) : null}

                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-danger">{formik.errors.firstName}</div>
                ) : null}

                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-danger">{formik.errors.lastName}</div>
                ) : null}

                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-danger">{formik.errors.address}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="introductionBy">
                    Who introduced you to ARI?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="introductionBy"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.introductionBy}
                  />
                  {formik.touched.introductionBy &&
                  formik.errors.introductionBy ? (
                    <div className="text-danger">
                      {formik.errors.introductionBy}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="message">Area of Interest</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="text-danger">{formik.errors.message}</div>
                  ) : null}
                </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="col-3">
            <img src={img01} width="250px" className="d-block m-auto" />
            <p className="text-center small">
              <small>ARI sections interaction </small>
            </p>
            <img src={img02} width="250px" className="d-block m-auto" />
            <p className="text-center small">
              <small>ARI target global network interaction</small>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contact;

//import img03 from "../images/who-03.png";

// const contact = () => {
//   return (
//     <main role="main" class="container">
//       <h3 className="text-center">Contact</h3>

//       <div className="container">
//         <div className="row">
//           <div className="col g-0"></div>
//           <div className="col-6">
//             {" "}
//             <p className="lead"></p>
//             <p>
//               New Kingdom Global - Web site – http://www.NewKingdomGlobal.com
//             </p>
//             <p>Reborn Global Trust -Web site – http://Reborne.us</p>
//             <p>
//               For information and expressions of interest please enter the
//               following information and return
//             </p>
//             <p>
//               <ul>
//                 <li>Company Name</li>
//                 <li>Name</li>
//                 <li>Address</li>
//                 <li>Email</li>
//                 <li> Who introduced you to ARI?</li>
//                 <li>Area of interest</li>
//                 <li>Send</li>
//               </ul>
//             </p>
//           </div>
//           <div className="col-3">
//             <img src={img01} width="250px" className="d-block m-auto" />
//             <p className="text-center small">
//               <small>ARI sections interaction </small>
//             </p>
//             <img src={img02} width="250px" className="d-block m-auto" />

//             <p className="text-center small">
//               <small>ARI target global network interaction</small>
//             </p>
//           </div>
//         </div>
//       </div>
//     </main>
//   );
// };

// //export default contact;
// class Contact extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       companyName: "",
//       name: "",
//       address: "",
//       email: "",
//       introductionBy: "",
//       message: "",
//     };
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     axios({
//       method: "POST",
//       url: "https://www.abundanceresearchinstitute.com/email.php",
//       data: this.state,
//     }).then((response) => {
//       //console.log(response.data);
//       if (response.data.status === "success") {
//         alert("Message Sent.");
//         this.resetForm();
//       } else if (response.data.status === "fail") {
//         alert("Message failed to send. Make sure all items are filled in");
//         //console.log(response.data.error);
//         response.data.error.map((sweetItem) => {
//           console.log(sweetItem);
//         });
//       }
//     });
//   }

//   resetForm() {
//     this.setState({
//       companyName: "",
//       name: "",
//       address: "",
//       email: "",
//       introductionBy: "",
//       message: "",
//     });
//   }

//   render() {
//     return (
//       <main role="main" class="container">
//         <h3 className="text-center">Contact</h3>

//         <div className="container">
//           <div className="row">
//             <div className="col g-0"></div>
//             <div className="col-6">
//               {" "}
//               <p className="lead"></p>
//               <p>
//                 New Kingdom Global - Web site – http://www.NewKingdomGlobal.com
//               </p>
//               <p>Reborn Global Trust -Web site – http://Reborne.us</p>
//               <p>
//                 For information and expressions of interest please enter the
//                 following information and return
//               </p>
//               {/*               <p>
//                 <ul>
//                   <li>Company Name</li>
//                   <li>Name</li>
//                   <li>Address</li>
//                   <li>Email</li>
//                   <li> Who introduced you to ARI?</li>
//                   <li>Area of interest</li>
//                   <li>Send</li>
//                 </ul>
//               </p> */}
//               <div className="App">
//                 <form
//                   id="contact-form"
//                   onSubmit={this.handleSubmit.bind(this)}
//                   method="POST"
//                 >
//                   <div className="form-group">
//                     <label htmlFor="companyName">Company Name</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="companyName"
//                       value={this.state.companyName}
//                       onChange={this.onCompanyNameChange.bind(this)}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="name">Name</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="name"
//                       value={this.state.name}
//                       onChange={this.onNameChange.bind(this)}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="address">Address</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="address"
//                       value={this.state.address}
//                       onChange={this.onAddressChange.bind(this)}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="exampleInputEmail1">Email address</label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       aria-describedby="emailHelp"
//                       value={this.state.email}
//                       onChange={this.onEmailChange.bind(this)}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="introductionBy">
//                       Who introduced you to ARI?
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="introductionBy"
//                       value={this.state.introductionBy}
//                       onChange={this.onIntroductionByChange.bind(this)}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="message">Area of Interest</label>
//                     <textarea
//                       className="form-control"
//                       rows="5"
//                       id="message"
//                       value={this.state.message}
//                       onChange={this.onMessageChange.bind(this)}
//                     />
//                   </div>
//                   <button type="submit" className="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//             <div className="col-3">
//               <img src={img01} width="250px" className="d-block m-auto" />
//               <p className="text-center small">
//                 <small>ARI sections interaction </small>
//               </p>
//               <img src={img02} width="250px" className="d-block m-auto" />

//               <p className="text-center small">
//                 <small>ARI target global network interaction</small>
//               </p>
//             </div>
//           </div>
//         </div>
//       </main>
//     );
//   }

//   onCompanyNameChange(event) {
//     this.setState({ companyName: event.target.value });
//   }

//   onNameChange(event) {
//     this.setState({ name: event.target.value });
//   }

//   onAddressChange(event) {
//     this.setState({ address: event.target.value });
//   }

//   onEmailChange(event) {
//     this.setState({ email: event.target.value });
//   }

//   onIntroductionByChange(event) {
//     this.setState({ introductionBy: event.target.value });
//   }
//   introductionBy;

//   onMessageChange(event) {
//     this.setState({ message: event.target.value });
//   }
// }

// export default Contact;
