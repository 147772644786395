import React, { Component } from "react";

export default class Footer extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <footer class="footer">
        <div class="container">
          <span class="text-muted"></span>
        </div>
      </footer>
    );
  }
}
