import React from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
//import Services from "../components/Services";
//import FeaturedRooms from "../components/FeaturedRooms";
const home = () => {
  return (
    <>
      <Hero>
        <Banner
          title="Get ready for the next wave"
          subtitle="To facilitate your research into visionary technologies in areas that will give glory to God."
        >
          <Link to="/what" className="btn btn-primary">
            What is the Abundance Research Institute?
          </Link>
        </Banner>
      </Hero>
    </>
  );
};

export default home;
