import React from "react";
import img01 from "../images/what-01.png";
import img02 from "../images/what-02.png";
//import img03 from "../images/who-03.png";

const what = () => {
  return (
    <main role="main" class="container">
      <h3 className="text-center">What do we do?</h3>

      <div className="container">
        <div className="row">
          <div className="col g-0"></div>
          <div className="col-6">
            {" "}
            <p className="lead">
              The selection process for entry into the Abundance Research
              Institute will be via a trusted team of Kingdom specialists and
              intercessors. The objective will be Kingdom success and benefit to
              those involved. (That all will prosper).
            </p>
            <p>
              In general, only those that we have an equity investment in will
              be selected and they will undertake a level of discipline in sound
              business development and wisdom.
            </p>
            <p>
              The Kingdom target market is very large (possibly as large as 1 in
              10 people) and growing on earth at this time and will likely
              expand quickly under a worldly spirit of persecution that appears
              to be growing.
            </p>
            <p>
              ARI will facilitate the research into visionary technologies in
              areas that will give glory to God.
            </p>
            <p>GLOBAL CHALLENGES FOR ABUNDANCE RESEARCH INSTITUTE </p>
            <p>NEW GLOBAL CHALLENGES</p>
            <ul>
              <li>Space and space junk</li>
              <li>IOT and Quantum computing</li>
            </ul>
            <p>EXISTING GLOBAL CHALLENGES</p>
            <ul className="list-group-horizontal-sm">
              <li>Environment/air</li>
              <li>Healthcare/wellbing/life</li>
              <li>Water/Resource Management</li>
              <li>Water/Land</li>
              <li>Education/Knowledge</li>
              <li>Energy/Resources</li>
              <li>Food/Housing</li>
              <li>Earth/Space</li>
            </ul>
            <p>
              In addition to promoting research and providing funding, guidance
              and facilities the Institute seeks to help researchers develop
              products and practices into sustainable business.{" "}
            </p>
            <p>
              At all stages of development researchers and innovators will be
              encouraged to consider how to create a business from their
              research that will provide income and employment.{" "}
            </p>
            <p>
              We will all be encouraged to mentor others who may benefit from
              our experience and insights.{" "}
            </p>
          </div>
          <div className="col-3">
            <img src={img01} width="250px" className="d-block m-auto" />
            <p></p>
            <img src={img02} width="250px" className="d-block m-auto" />

            <p className="text-center small">
              <small>Examples of typical Centres</small>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default what;
