import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FaAlignRight } from "react-icons/fa";
import logo from "../images/ari.png";
export default class NavbarARI extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <>
        <Navbar expand="sm" bg="primary" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="ARI" />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/what">What</Nav.Link>
              <Nav.Link href="/who">Who</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
    // return (
    //   <nav className="navbar navbar-expand-sm navbar-dark bg-primary">
    //     <a className="navbar-brand" href="#">
    //       ARI
    //     </a>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       data-toggle="collapse"
    //       data-target="#navbarNav"
    //       aria-controls="navbarNav"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav">
    //         <li className="nav-item active">
    //           <a className="nav-link" href="/">
    //             Home{" "}
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="/what">
    //             What
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="/who">
    //             Who
    //           </a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="/contact">
    //             Contact
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //   </nav>
    // );
    /*   return (
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <Link to="/">
              <img src={logo} alt="ARI" />
            </Link>
            <button
              type="button"
              className="nav-btn"
              onClick={this.handleToggle}
            >
              <FaAlignRight className="nav-icon" />
            </button>
          </div>
          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/what">What</Link>
            </li>
            <li>
              <Link to="/who">Who</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    ); */
  }
}
